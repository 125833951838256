import React from "react";
import { useTranslation } from "react-i18next";

const Error = ({
    errorMessage = ''
}) => {

    const { t } = useTranslation();

    return (
        <div className="flex items-center text-xs font-medium text-red mt-[5px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[16px] shrink-0 h-[16px] mr-[5px]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
            </svg>

            {
                !!errorMessage ? errorMessage : t('errors.requiredField')
            }
        </div>
    );
}

export default Error;