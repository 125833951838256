import i18n from '../../i18n';

import {
    validateRequired,
    populateValidationRequired,
    validateEmail,
    populateValidationEmail,
    populateValidationConfirmEmail,
    validateConfirmEmail,
    validateFleetVehicles,
    populateValidationFleetVehicles,
    validateTelephone,
    populateValidationTelephone,
    validateNotNumeric,
    populateValidationSurnameFormat,
    populateValidationNameFormat
} from "./validations";

export const validateForm = (data) => {
    let errorObj = {};

    if (!validateRequired(data.country)) {
        errorObj.country = populateValidationRequired(i18n.t('errors.requiredCountry'));
    }

    if (!validateRequired(data.vat)) {
        errorObj.vat = populateValidationRequired(i18n.t('errors.requiredVat'));
    }

    if (!validateRequired(data.enterpriseName)) {
        errorObj.enterpriseName = populateValidationRequired(i18n.t('errors.requiredEnterpriseName'));
    }

    if (!validateRequired(data.address)) {
        errorObj.address = populateValidationRequired(i18n.t('errors.requiredAddress'));
    }

    if (!validateRequired(data.city)) {
        errorObj.city = populateValidationRequired(i18n.t('errors.requiredCity'));
    }

    if (!validateRequired(data.region)) {
        errorObj.region = populateValidationRequired(i18n.t('errors.requiredRegion'));
    }

    if (!validateRequired(data.postalCode)) {
        errorObj.postalCode = populateValidationRequired(i18n.t('errors.requiredPostalCode'));
    }

    if (!validateRequired(data.contactName)) {
        errorObj.contactName = populateValidationRequired(i18n.t('errors.requiredContactName'));
    } else if (!validateNotNumeric(data.contactName)) {
        errorObj.contactName = populateValidationNameFormat();
    }

    if (!validateRequired(data.contactSurname)) {
        errorObj.contactSurname = populateValidationRequired(i18n.t('errors.requiredContactSurname'));
    } else if (!validateNotNumeric(data.contactSurname)) {
        errorObj.contactSurname = populateValidationSurnameFormat();
    }

    if (!validateRequired(data.prefix)) {
        errorObj.prefix = populateValidationRequired(i18n.t('errors.requiredPrefix'));
    }

    if (!validateRequired(data.telephone)) {
        errorObj.telephone = populateValidationRequired(i18n.t('errors.requiredTelephone'));
    } else if (!validateTelephone(data.telephone)) {
        errorObj.telephone = populateValidationTelephone();
    }

    if (!validateRequired(data.role)) {
        errorObj.role = populateValidationRequired(i18n.t('errors.requiredRole'));
    }

    if (!validateRequired(data.email)) {
        errorObj.email = populateValidationRequired(i18n.t('errors.requiredEmail'));
    } else if (!validateEmail(data.email)) {
        errorObj.email = populateValidationEmail();
    }

    if (!validateRequired(data.confirmEmail)) {
        errorObj.confirmEmail = populateValidationRequired(i18n.t('errors.requiredConfirmEmail'));
    } else if (!validateEmail(data.confirmEmail)) {
        errorObj.confirmEmail = populateValidationEmail();
    } else if (!validateConfirmEmail(data.email, data.confirmEmail)) {
        errorObj.confirmEmail = populateValidationConfirmEmail();
    }

    if (!validateRequired(data.vendorType)) {
        errorObj.vendorType = populateValidationRequired(i18n.t('errors.requiredSupplierType'));
    } else {
        if (!validateFleetVehicles(data.vendorType, data.fleet)) {
            errorObj.fleet = populateValidationFleetVehicles();
        }
    }

    if (!validateRequired(data.privacyAccepted)) {
        errorObj.privacyAccepted = populateValidationRequired(i18n.t('errors.requiredPrivacyAccepted'));
    }

    return errorObj;
}