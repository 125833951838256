import axios from "axios";
import settings from "../configs/settings";
import i18n from "../i18n";

class basicRequest {
    constructor() {
        // inizializzo axios in una variabile globale
        this.instance = axios.create({
            baseURL: settings.baseApiUrl, // base url del server
            // timeout: 20000, // MS                   
        });
    }

    // Chiamata API CRUD
    // GET (READ)
    get = async (path, params = null) => {
        return await this.instance.get(path, {
            params: {
                lang: i18n.resolvedLanguage,
                ...params
            }
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            console.log('error', error);
            return {
                success: false
            }
        });
    };

    // POST (Create)
    post = async (path, obj = {}) => {
        return await this.instance.post(path, obj)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log('error', error);
                return {
                    success: false,
                    statusCode: error.response.status
                }
            });
    };
}

export default basicRequest;