import React from "react";

const Modal = ({
    children,
    visible = false,
    callbackClose
}) => {

    const handleClose = () => {
        if (callbackClose) {
            callbackClose();
        }
    }

    if (visible) {
        return (
            <div className="fixed p-6 lg:p-0 z-10 top-0 left-0 z-10 h-[100dvh] w-screen bg-modalBackground flex items-center justify-center">
                <div className="bg-white px-6 py-14 lg:px-16 lg:py-16 rounded-[20px] w-full lg:w-[60%] lg:max-w-[780px] relative">
                    <div onClick={handleClose} className="absolute cursor-pointer top-[22.5px] right-[22.5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#cf0a2c" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <>
                        {children}
                    </>
                </div>
            </div>
        )
    }
}

export default Modal;