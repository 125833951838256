import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../../configs/languages/languages";

const SwitchLanguage = ({
    positionX = 'right'
}) => {
    const { i18n } = useTranslation();

    const switchRef = useRef(null);

    const [open, setOpen] = useState(false);

    const openClose = () => {
        setOpen(!open);
    }

    useEffect(() => {
        const listener = (e) => {
            if (switchRef && switchRef.current && !switchRef.current.contains(e.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('click', listener)
        return () => {
            document.removeEventListener('click', listener);
        }
    }, []);

    const changeLanguage = (lng) => () => {
        i18n.changeLanguage(lng);
        setOpen(false);
    }

    const getPositionX = () => {
        return positionX === "right" ? " left-0" : " right-0"
    }

    return (
        <div ref={switchRef} className="relative">
            <div onClick={openClose} className="cursor-pointer text-white uppercase inline-flex items-end select-none">
                <span className="mr-[6px]">{i18n.resolvedLanguage}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-[12px] h-[12px] mb-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </div>

            {
                open &&
                <div className={"absolute cursor-pointer bg-white top-[100%] border border-darkBeige rounded py-[5px] shadow min-w-[100px]" + getPositionX()}>
                    <ul>
                        {
                            supportedLanguages && supportedLanguages.length > 0 && supportedLanguages.map((lang, index) => {
                                return (
                                    <li key={index} onClick={changeLanguage(lang)} className="p-2 hover:bg-beige transition">{lang.toUpperCase()}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
        </div>
    );
}

export default SwitchLanguage;