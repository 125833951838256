import React, { useEffect, useRef } from "react";
import SidebarStep from "../components/sidebarStep/SidebarStep";
import Button from "../components/button/Button";
import { useTranslation } from "react-i18next";
import SwitchLanguage from "../components/switchLanguage/SwitchLanguage";
import logoArcese from "../assets/images/arcese_logotipo.png";

const Layout = ({
    children,
    activeElementId,
    callbackOnScroll,
    callbackSetOffsetTop,
    callbackOnSubmit,
    isLoadingSubmit,
    isCompleted
}) => {

    const { t } = useTranslation();

    const mainRef = useRef(null);
    useEffect(() => {
        const listener = () => {
            if (mainRef && mainRef.current && callbackOnScroll) {
                callbackOnScroll(mainRef.current.scrollTop)
            }
        }

        if (mainRef && mainRef.current && callbackSetOffsetTop) {
            mainRef.current.addEventListener('scroll', listener)
            callbackSetOffsetTop(mainRef.current.offsetTop);
        }

        return () => {
            if (mainRef.current) {
                mainRef.current.removeEventListener('scroll', listener);
            }
        }
    }, []);

    const handleSubmitForm = () => {
        if (callbackOnSubmit) {
            callbackOnSubmit();
        }
    }

    return (
        <div className="lg:w-screen h-[100dvh] flex font-roboto">
            {/* Sidebar */}
            <aside id="sidebar" className="hidden lg:flex w-[25%] max-w-[364px] bg-red shrink-0 flex-col">
                <div className="py-[38px] pl-[38px] grow flex flex-col">
                    <div className="flex items-center justify-between mb-[78px] pr-[38px]">
                        <div>
                            <img src={logoArcese} className="h-9 w-auto" alt="logo arcese" />
                        </div>

                        {/* Switch Language */}
                        <div>
                            <SwitchLanguage />
                        </div>
                    </div>

                    {
                        isCompleted === false &&
                        <div>
                            <div className="mb-[24px]">
                                <SidebarStep
                                    number="1"
                                    label={t('sections.enterpriseData')}
                                    id="enterprise-data"
                                    isActive={activeElementId === 'enterprise-data'}
                                />
                            </div>

                            <div className="mb-[24px]">
                                <SidebarStep
                                    number="2"
                                    label={t('sections.contactData')}
                                    id="contact-data"
                                    isActive={activeElementId === 'contact-data'}
                                />
                            </div>

                            <SidebarStep
                                number="3"
                                label={t('sections.fleetDetails')}
                                id="fleet-data"
                                isActive={activeElementId === 'fleet-data'}
                            />
                        </div>
                    }
                </div>
            </aside>

            {
                isCompleted === false &&
                <>
                    {/* Main Contents */}
                    <div className="grow lg:pt-[35px] flex flex-col">
                        <div className="grow flex flex-col overflow-hidden">
                            {/* Heading */}
                            <header className="shrink-0 w-full lg:w-[80%] lg:max-w-[768px] m-auto">

                                {/* Only Mobile */}
                                <div className="lg:hidden bg-red ">
                                    <div className="px-6 flex justify-between items-center  h-[86px]">
                                        <div>
                                            <img src={logoArcese} alt="logo arcese" className="h-9 w-auto" />
                                        </div>

                                        <SwitchLanguage positionX="left" />
                                    </div>


                                    <div className="md:hidden px-6 flex justify-between items-center py-3 border-t border-t border-white">
                                        <SidebarStep
                                            number="1"
                                            label={t('sections.enterpriseData')}
                                            id="enterprise-data"
                                            isActive={activeElementId === 'enterprise-data'}
                                        />

                                        <SidebarStep
                                            number="2"
                                            label={t('sections.contactData')}
                                            id="contact-data"
                                            isActive={activeElementId === 'contact-data'}
                                        />

                                        <SidebarStep
                                            number="3"
                                            label={t('sections.fleetDetails')}
                                            id="fleet-data"
                                            isActive={activeElementId === 'fleet-data'}
                                        />
                                    </div>
                                </div>


                                <div className="hidden lg:block mx-6 lg:mx-0 border-b py-6 lg:pb-[35px] lg:pt-0 border-darkBeige">
                                    <h1 className="text-red font-light text-[32px] lg:text-[35px] uppercase text-center mb-5">{t('title')}</h1>
                                    <p className="text-gray text-[15px] lg:text-base leading-[25px] font-normal text-center">{t('paragraph')} <strong>{t('paragraphStrong')}</strong></p>
                                </div>
                            </header>

                            {/* Content childrens */}
                            <main ref={mainRef} className="grow overflow-auto main-content">
                                <div className="lg:hidden mx-6 lg:mx-0 border-b py-6 lg:pb-[35px] lg:pt-0 border-darkBeige">
                                    <h2 className="text-red font-light text-[32px] lg:text-[35px] uppercase text-center mb-5">{t('title')}</h2>
                                    <p className="text-gray text-[15px] lg:text-base leading-[25px] font-normal text-center">{t('paragraph')} <strong>{t('paragraphStrong')}</strong></p>
                                </div>

                                <div className="px-6 pb-[80px] lg:px-0 lg:pb-0 w-full lg:w-[80%] lg:max-w-[768px] m-auto">
                                    {children}
                                </div>
                            </main>
                        </div>

                        <footer className="fixed lg:static bottom-0 left-0 w-full bg-white shrink-0 py-[20px] flex justify-center border-t border-darkBeige">
                            <Button loading={isLoadingSubmit} className="min-h-[42px]" callback={handleSubmitForm}>
                                {t('buttonSubmit')}
                            </Button>
                        </footer>
                    </div>
                </>
            }

            {
                isCompleted === true &&
                <div className="text-center mx-auto w-[80%] lg:w-[50%] mt-[120px]">
                    <h3 className="text-red text-[24px] mb-5">{t('modal.success.title')}</h3>
                    <p dangerouslySetInnerHTML={{ __html: t('modal.success.paragraph') }} className="text-[15px] text-[#757575] font-medium"></p>

                    <Button callback={() => window.location.href = "https://arcese.com"} className="mx-auto mt-[40px]">
                        {t('goToArceseSite')}
                    </Button>
                </div>
            }
        </div>
    );
}

export default Layout;