import React from "react";
import Error from "../error/Error";
import Spinner from "../spinner/Spinner";

const InputText = ({
    label,
    type = 'text',
    placeholder,
    value,
    name,
    callbackOnChange,
    callbackOnFocus,
    callbackOnBlur,
    containerClass,
    hasError = false,
    errorMessage,
    disabled = false,
    loading = false
}) => {

    const handleChange = (e) => {
        if (callbackOnChange) {
            callbackOnChange(e.target.value);
        }
    }

    const handleFocus = () => {
        if (callbackOnFocus) {
            callbackOnFocus();
        }
    }

    const handleBlur = () => {
        if (callbackOnBlur) {
            callbackOnBlur();
        }
    }

    return (
        <div className={containerClass}>
            {
                label &&
                <label className="block mb-1 text-gray font-medium uppercase text-xs" htmlFor={name}>{label}</label>
            }

            <div className="relative">
                <input
                    className={"input-text text-black text-[15px] bg-beige border border-darkBeige rounded-[8px] p-[10px] w-full" + (hasError ? ' border-red' : '')}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    id={name}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={disabled}
                />

                {
                    !!loading &&
                    <div className="absolute right-[10px] top-1/2 -translate-y-1/2">
                        <Spinner color="#cf0a2c" />
                    </div>
                }
            </div>

            {
                hasError &&
                <Error errorMessage={errorMessage} />
            }
        </div>
    );
}

export default InputText;