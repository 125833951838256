import settings from "../../configs/settings";

export const TRANSLATION_EN = {
    errorFallback: 'Something went wrong while loading the service. We apologize and please try again later.',
    errorFallbackButtonLabel: 'Refresh page',
    title: 'Become a partner now',
    paragraph: 'Fill out the following form and discover all the other advantages for our partners and how to access more transport options and collaborate throughout Europe.',
    paragraphStrong: 'Start driving with us!',
    buttonSubmit: 'Submit application',
    backToArceseSite: 'Return to the Arcese website',
    goToArceseSite: "Go to arcese.com",
    becomePartner: 'Become a partner',
    requiredFields: 'required fields',
    sections: {
        enterpriseData: 'Company data',
        contactData: 'Contact details',
        fleetDetails: 'Fleet details'
    },
    modal: {        
        success: {
            title: 'Thank you for submitting your application!',
            paragraph: 'Your application is under evaluation. We have sent you an email to the address entered with the registration data.<br /><br />You will receive the result as soon as possible.',
        },
        alreadyCompleted: 'You have already completed your application! You can close the browser window.',
        errorResponseData: 'There was an error sending your application. Please try again later.',
        errorDuplicatedApplication: 'An account with the same vat number already exists.'
    },    
    inputs: {
        country: {
            label: 'Country*',
            placeholder: 'Select country'
        },
        vat: {
            label: 'VAT number*',
            placeholder: 'Enter the VAT number',
            genericTaxCode: 'Generic Tax Code',
            genericTaxCodePlaceholder: 'Enter Generic Tax Code'
        },
        enterpriseData: {
            label: 'Business name*',
            placeholder: 'Enter the company name'
        },
        registeredOffice: "Registered office",
        address: {
            label: 'Address*',
            placeholder: 'Enter the address'
        },
        city: {
            label: 'City*',
            placeholder: 'Enter the city'
        },
        region: {
            label: 'Province*',
            placeholder: 'Enter the province'
        },
        postalCode: {
            label: 'Zip code*',
            placeholder: 'Enter the zip code'
        },
        contactName: {
            label: 'Name*',
            placeholder: 'Enter your name'
        },
        contactSurname: {
            label: 'Surname*',
            placeholder: 'Enter the last name'
        },
        prefix: {
            label: 'Int. prefix*',
            placeholder: 'Select prefix'
        },
        telephone: {
            label: 'Telephone number*',
            placeholder: 'Enter your phone number'
        },
        role: {
            label: 'Role/Department*',
            placeholder: 'Select role or department'
        },
        email: {
            label: 'Email address*',
            placeholder: 'Enter a contact email address'
        },
        confirmEmail: {
            label: 'Confirm email address*',
            placeholder: 'Confirm contact email address'
        },
        supplierType: "What kind of supplier are you?*",
        fleet: "Tell us how your fleet is made up*",
        privacyAccepted: `I declare that I have read and accept the <a href='${settings.privacyUrlEng}' style='text-decoration: underline; color: #cf0a2c' target='_blank'>privacy policy</a> *`,
        marketingAccepted: "I authorize the processing of my data for marketing purposes",
        collaborationConsent: "I consent to receive communications for future partnerships regarding new routes and services"
    },
    errors: {
        isThereAnError: "There are fields with errors",
        requiredField: 'The field is required',
        wrongEmailFormat: 'The email format is incorrect',
        wrongConfirmEmail: 'The emails entered must match',
        wrongTelephoneFormat: 'The format of the phone number is incorrect',
        wrongVatFormat: 'The format of the vat number is incorrect',
        vehicles: 'Please select at least one fleet element',
        requiredCountry: 'Enter the country',
        requiredVat: 'Enter the VAT number',
        requiredEnterpriseName: 'Enter the company name',
        requiredAddress: 'Enter the address',
        requiredCity: 'Enter the city',
        requiredRegion: 'Enter the province',
        requiredPostalCode: 'Enter the zip code',
        requiredContactName: 'Enter the contact name',
        requiredContactSurname: 'Enter the last name',
        requiredPrefix: 'Enter the telephone prefix',
        requiredTelephone: 'Enter the contact phone',
        requiredRole: 'Enter role or department',
        requiredEmail: 'Please enter your email',
        requiredConfirmEmail: 'Enter your email confirmation',
        requiredSupplierType: 'Select at least one vendor type',
        requiredPrivacyAccepted: 'Mark the privacy field',
        wrongFormatName: "Name can't contain numbers",
        wrongFormatSurname: "Surname can't contain numbers"
    }
}