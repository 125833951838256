import React from "react";
import logo from "../../assets/images/arcese_logo_text_red.svg";
import { useTranslation } from "react-i18next";
import Button from "../button/Button";

const ErrorFallback = () => {

    const { t } = useTranslation();

    const handleClick = () => {
        window.location.reload();
    }

    return (
        <>
            <div className="h-[100dvh] w-screen flex justify-center items-center">
                <div className="mb-16 w-[80%] max-w-[768px] text-center flex flex-col items-center">
                    <img src={logo} className="block h-[45px] w-auto mb-10" alt="Logo Arcese" />
                    <p>{t('errorFallback')}</p>
                    <Button className="mt-5" callback={handleClick}>
                        {t('errorFallbackButtonLabel')}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ErrorFallback;