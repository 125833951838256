import React, { useEffect, useRef } from "react";

const StepContainer = ({
    title,
    id,
    number,
    children,
    scrollValue,
    containerOffsetTop,
    callbackOnEnter
}) => {

    const stepRef = useRef(null);

    useEffect(() => {
        if (callbackOnEnter && stepRef && stepRef.current) {
            const comparisonValue = scrollValue - (stepRef.current.offsetTop - containerOffsetTop);
            if (comparisonValue > 0 && comparisonValue < stepRef.current.scrollHeight) {
                callbackOnEnter();
            }
        }       
    }, [scrollValue, containerOffsetTop])

    return (
        <section ref={stepRef} className="py-[30px]">
            <div className="flex justify-center mb-[30px]">
                <h3 id={id} className="scroll-mt-3 flex text-red items-center shrink-0">
                    <div className="h-[44px] w-[44px] text-[20px] font-bold leading-[44px] rounded-full border border-red flex items-center justify-center">
                        {number}
                    </div>

                    <div className="pl-[10px] label-sidebar-step text-[24px] font-light">
                        {title}
                    </div>
                </h3>
            </div>

            <div>
                {children}
            </div>
        </section>
    );
}

export default StepContainer;