import React from "react";
import Error from "../error/Error";

const Checkbox = ({
    callback,
    label,
    name,
    className,
    hasError = false,
    errorMessage = '',
    callbackOnFocus
}) => {

    const handleChange = (e) => {
        if (callback) {
            callback(e.target.checked);
        }
    }

    const handleFocus = () => {
        if (callbackOnFocus) {
            callbackOnFocus();
        }
    }

    return (
        <div className={className}>
            <div className="flex items-center">
                <input
                    onClick={handleChange}
                    className="block cursor-pointer"
                    type="checkbox"
                    name={name}
                    id={name}
                    onFocus={handleFocus}
                />

                {
                    !!label &&
                    <label
                        dangerouslySetInnerHTML={{ __html: label }}
                        htmlFor={name}
                        className="mb-[2px] ml-[5px] cursor-pointer select-none leading-[17.5px] text-[15px] text-gray font-semibold"
                    />
                }
            </div>

            {
                hasError &&
                <Error errorMessage={errorMessage} />
            }
        </div>
    );
}

export default Checkbox;