import settings from "../../configs/settings";

export const TRANSLATION_IT = {
    errorFallback: 'Qualcosa è andato storto nel caricamento del servizio. Ci scusiamo e invitiamo a provare più tardi.',
    errorFallbackButtonLabel: 'Ricarica la pagina',
    title: 'Diventa subito partner',
    paragraph: 'Compila il seguente form e scopri tutti gli altri vantaggi per i nostri partner e come accedere a più opzioni di trasporto e collaborare in tutta Europa.',
    paragraphStrong: 'Inizia a guidare con noi!',
    buttonSubmit: 'Invia candidatura',    
    backToArceseSite: 'Torna al sito Arcese',
    goToArceseSite: "Vai ad arcese.com",
    becomePartner: 'Diventa partner',
    requiredFields: 'campi obbligatori',
    sections: {
        enterpriseData: 'Dati azienda',
        contactData: 'Dati di contatto',
        fleetDetails: 'Dettagli flotta'
    },
    modal: {
        success: {
            title: "Grazie per aver inviato la tua candidatura!",
            paragraph: 'La tua candidatura è in fase di valutazione. Ti abbiamo inviato una mail all\'indirizzo inserito con i dati relativi alla registrazione.<br /><br />Riceverai al più presto l\'esito.',
        },
        alreadyCompleted: 'Hai già completato la candidatura! Puoi chiudere la finestra del browser.',
        errorResponseData: 'C\'è stato un errore durante l\'invio della candidatura. Si prega di riprovare più tardi.',
        errorDuplicatedApplication: 'Esiste già un account con la stessa partità IVA.'
    },
    inputs: {
        country: {
            label: 'Nazione*',
            placeholder: 'Seleziona nazione'
        },
        vat: {
            label: 'Partita IVA*',
            placeholder: 'Inserisci la Partita IVA',
            genericTaxCode: 'Codice Fiscale Generico',
            genericTaxCodePlaceholder: 'Inserisci il Codice Fiscale Generico'
        },
        enterpriseData: {
            label: 'Ragione Sociale*',
            placeholder: 'Inserisci la ragione sociale'
        },
        registeredOffice: "Sede legale",
        address: {
            label: 'Indirizzo*',
            placeholder: 'Inserisci l\'indirizzo'
        },
        city: {
            label: 'Città*',
            placeholder: 'Inserisci la città'
        },
        region: {
            label: 'Provincia*',
            placeholder: 'Inserisci la provincia'
        },
        postalCode: {
            label: 'CAP*',
            placeholder: 'Inserisci il CAP'
        },
        contactName: {
            label: 'Nome*',
            placeholder: 'Inserisci il nome'
        },
        contactSurname: {
            label: 'Cognome*',
            placeholder: 'Inserisci il cognome'
        },
        prefix: {
            label: 'Prefisso Int.*',
            placeholder: 'Seleziona prefisso'
        },
        telephone: {
            label: 'Numero di telefono*',
            placeholder: 'Inserisci il numero di telefono'
        },
        role: {
            label: 'Ruolo/Dipartimento*',
            placeholder: 'Seleziona ruolo o dipartimento'
        },
        email: {
            label: 'Indirizzo email*',
            placeholder: 'Inserisci un indirizzo email di contatto'
        },
        confirmEmail: {
            label: 'Conferma indirizzo email*',
            placeholder: 'Conferma indirizzo email di contatto'
        },
        supplierType: "Che tipo di fornitore sei?*",
        fleet: "Indicaci com'è composta la tua flotta*",
        privacyAccepted: `Dichiaro di aver letto e di accettare <a href='${settings.privacyUrlIta}' style='text-decoration: underline; color: #cf0a2c' target='_blank'>l'informativa sulla Privacy</a> *`,
        marketingAccepted: "Autorizzo al trattamento dei miei dati per finalità di marketing",
        collaborationConsent: "Acconsento a ricevere comunicazioni per future partnership relative a nuove rotte e servizi"
    },
    errors: {
        isThereAnError: "Ci sono campi con errori",
        requiredField: 'Il campo è richiesto',
        wrongEmailFormat: 'Il formato della email non è corretto',
        wrongConfirmEmail: 'Le email inserite devono corrispondere',
        wrongTelephoneFormat: 'Il formato del numero di telefono non è corretto',
        wrongVatFormat: 'Il formato della Partita IVA non è corretto',
        vehicles: 'Selezionare almeno un elemento di flotta',
        requiredCountry: 'Inserire la nazione',
        requiredVat: 'Inserire la Partita IVA',
        requiredEnterpriseName: 'Inserire la ragione sociale',
        requiredAddress: 'Inserire l\'indirizzo',
        requiredCity: 'Inserire la città',
        requiredRegion: 'Inserire la provincia',
        requiredPostalCode: 'Inserire il CAP',
        requiredContactName: 'Inserire il nome di contatto',
        requiredContactSurname: 'Inserire il cognome',
        requiredPrefix: 'Inserire il prefisso telefonico',
        requiredTelephone: 'Inserire il telefono di contatto',
        requiredRole: 'Inserire ruolo o dipartimento',
        requiredEmail: 'Inserire la email',
        requiredConfirmEmail: 'Inserire la conferma email',
        requiredSupplierType: 'Selezionare almeno un tipo di fornitore',
        requiredPrivacyAccepted: 'Contrassegnare il campo privacy',
        wrongFormatName: "Il nome non può contenere caratteri numerici",
        wrongFormatSurname: "Il cognome non può contenere caratteri numerici"
    }  
}