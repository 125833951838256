export const formatNominatimAddress = (data) => {
    if (!!data.address && !!data.address.road && data.address.postcode && !!data.address['ISO3166-2-lvl6'] && (!!data.address.town || !!data.address.city || !!data.address.county)) {
        let region = data.address['ISO3166-2-lvl6'];
        if(region.includes('IT-')) {
            region = region.slice(3);
        }

        let city = '';
        if(!!data.address.city) {
            city = data.address.city;
        } else if(!!data.address.town) {
            city = data.address.town;
        } else if(!!data.address.county) {
            city = data.address.county;
        }

        let address = data.address.road + (!!data.address.house_number ? (' ' + data.address.house_number) : '');

        return {
            success: true,
            data: {
                address: address,
                city: city,
                region: region,
                postalCode: data.address.postcode,
            }
        }
    } else {
        return {
            success: false
        }
    }
}