import React from "react";

const Tab = ({
    label,
    value,
    callback,
    active = false
}) => {

    const handleClick = () => {
        if(callback) {
            callback(value);
        }
    }

    return (
        <div 
            onClick={handleClick} 
            className={"select-none rounded-[70px] border text-[14px] font-medium text-center px-[21px] py-4 mb-5 mr-5 cursor-pointer" + (active ? ' bg-red border-red text-white' : ' bg-beige border-darkBeige text-black')}
        >
            {label}
        </div>
    );
}

export default Tab;