import React, { useEffect, useState, useRef, useMemo } from "react";
import Layout from "../layouts/Layout";
import StepContainer from "./stepContainer/StepContainer";
import InputText from "./inputText/InputText";
import Select from "./select/Select";
import Tab from "./tab/Tab";
import { validateForm } from "../helpers/validation/validateForm";
import Checkbox from "./checkbox/Checkbox";
import Error from "./error/Error";
import { useTranslation } from "react-i18next";
// import { vatResponse } from "../configs/dataMock/vatResponse";
import Modal from "./modal/Modal";
import { supportedLanguages } from "../configs/languages/languages";
import { api } from "../api/api";

// Mock
// import countriesMock from "../configs/dataMock/countries";
// import rolesMock from "../configs/dataMock/roles";
// import vendorTypesMock from "../configs/dataMock/vendorTypes";
// import vehiclesMock from "../configs/dataMock/vehicles";
import ErrorFallback from "./errorFallback/ErrorFallback";
import { formatNominatimAddress } from "../helpers/formatNominatimAddress";

const App = () => {
	const { t, i18n } = useTranslation();

	const [data, setData] = useState({
		country: '',
		vat: '',
		enterpriseName: '',
		address: '',
		city: '',
		region: '',
		postalCode: '',
		contactName: '',
		contactSurname: '',
		role: '',
		prefix: '',
		telephone: '',
		email: '',
		confirmEmail: '',
		vendorType: null,
		fleet: null,
		privacyAccepted: false,
		marketingAccepted: false,
		collaborationConsent: false
	});

	const [errors, setErrors] = useState({});

	const [activeElementId, setActiveElementId] = useState('enterprise-data');
	const [actualScroll, setActualScroll] = useState(0);
	const [containerOffsetTop, setContainerOffsetTop] = useState(0);
	const [formCompleted, setFormCompleted] = useState(false);
	const [vendorTypeModalOpen, setVendorTypeModalOpen] = useState(false);

	// ApiData	
	const [countriesPrefixes, setCountriesPrefixes] = useState([]);
	const [roles, setRoles] = useState([]);
	const [vendorTypes, setVendorTypes] = useState([]);
	const [vehicles, setVehicles] = useState([]);

	const [errorApi, setErrorApi] = useState(false);
	const [errorResponseData, setErrorResponseData] = useState(false);
	const [errorDuplicatedApplication, setErrorDuplicatedApplication] = useState(false);
	const [loadingResponseData, setLoadingResponseData] = useState(false);

	const shouldGo = useRef(true);
	useEffect(() => {
		if (shouldGo.current) {
			shouldGo.current = false;

			// Detect lang from URL params
			setLanguage();

			// Set data from API
			setInitialData();
		}
	}, []);

	/** 
	  * CHIAMATE API
	  */
	const setInitialData = async () => {
		// const countriesPrefixesArray = countriesMock.responseBody;
		const countriesPrefixesArray = handleApiResponse(await api.countriesPrefixes());
		setCountriesPrefixes(countriesPrefixesArray);

		// const rolesArray = rolesMock.responseBody;
		const rolesArray = handleApiResponse(await api.roles());
		setRoles(rolesArray)

		// const vendorTypesArray = vendorTypesMock.responseBody;
		const vendorTypesArray = handleApiResponse(await api.vendorTypes());
		setVendorTypes(vendorTypesArray)

		// const vehiclesArray = vehiclesMock.responseBody;
		const vehiclesArray = handleApiResponse(await api.vehicles());
		setInitialFleet(vehiclesArray);
		setVehicles(groupVehicles(vehiclesArray));
	}

	const handleApiResponse = (response) => {
		if (response.success) {
			return response.responseBody;
		}
		setErrorApi(true);
		return [];
	}

	// Cambiare istantaneamente lingua alle traduzioni
	useEffect(() => {
		// Check if there are errors
		let error = false;
		for (let property in errors) {
			if (errors[property].error === true) {
				error = true;
			}
		}

		if (error) {
			const errorObj = validateForm(data);
			setErrors({
				...errors,
				...errorObj
			});
		}

		refreshDataTranslation();
	}, [i18n.resolvedLanguage]);

	const refreshDataTranslation = async () => {
		// const rolesArray = rolesMock.responseBody;
		const rolesArray = handleApiResponse(await api.roles());
		setRoles(rolesArray);

		// const vendorTypesArray = vendorTypesMock.responseBody;
		const vendorTypesArray = handleApiResponse(await api.vendorTypes());
		setVendorTypes(vendorTypesArray);

		const vehiclesArray = handleApiResponse(await api.vehicles());
		setVehicles(groupVehicles(vehiclesArray));

		const countriesArray = handleApiResponse(await api.countriesPrefixes());
		setCountriesPrefixes(countriesArray);
	}

	const setLanguage = () => {
		const searchParams = new URL(document.location).searchParams;
		if (searchParams.get("lang")) {
			i18n.changeLanguage(supportedLanguages.includes(searchParams.get("lang")) ? searchParams.get("lang") : 'en');
			window.history.replaceState('', '', '/');
		}
	}

	const setInitialFleet = (vehiclesArray) => {
		const initialFleet = [...vehiclesArray].map(v => {
			return {
				...v,
				quantity: 0
			}
		});

		setData({
			...data,
			fleet: initialFleet
		})
	}

	const handleScroll = (e) => {
		setActualScroll(e);
	}

	const [isCee, setIsCee] = useState(true);
	const onChangeText = (stateName, value) => {

		// Set is vies false if changes address
		if (stateName === 'address' || stateName === "city" || stateName === "region" || stateName === "postalCode" || stateName === "country") {
			setIsViesAddress(false);
		}

		const objData = {
			[stateName]: value
		}

		if (stateName === 'country') {
			const country = countriesPrefixes.find(c => c.id === value);
			if (!!country) {
				setIsCee(country.isCEE);
			}

			objData.prefix = country.id;
		}

		setData({
			...data,
			...objData
		})
	}

	const groupVehicles = (data) => {
		let vehiclesArray = [];
		data.forEach(vehicle => {
			if (!vehiclesArray.find(v => v.type === vehicle.type)) {
				vehiclesArray.push({
					type: vehicle.type,
					vehicles: [{ ...vehicle, quantity: 0 }]
				});
			} else {
				const index = vehiclesArray.findIndex(v => v.type === vehicle.type);
				vehiclesArray[index].vehicles.push({ ...vehicle, quantity: 0 });
			}
		});

		return vehiclesArray;
	}

	const resetFleet = () => {
		return [...data.fleet].map(v => {
			return {
				...v,
				quantity: 0
			}
		})
	}

	const handleTabChange = (e) => {
		let dataObj = {
			vendorType: e,
			fleet: resetFleet()
		};

		setData({
			...data,
			...dataObj
		});

		setErrors({
			...errors,
			vendorType: {
				error: false,
				message: ''
			}
		})
	}

	const findFleetVehicle = (vehicleId) => {
		if (data.fleet && data.fleet.length > 0) {
			const vehicle = data.fleet.find(v => v.id === vehicleId);
			return vehicle;
		}
	}

	const addVehicleToFleet = (vehicleId) => () => {
		let fleetCopy = [...data.fleet];
		const indexOfVehicle = fleetCopy.findIndex(v => v.id === vehicleId);
		if (indexOfVehicle !== -1) {
			fleetCopy[indexOfVehicle].quantity += 1;

			setData({
				...data,
				fleet: fleetCopy
			});

			resetError('fleet')();
		}
	}

	const removeVehicleToFleet = (vehicleId) => () => {
		let fleetCopy = [...data.fleet];

		if (fleetCopy && fleetCopy.length > 0) {
			const indexOfVehicle = fleetCopy.findIndex(v => v.id === vehicleId);

			if (indexOfVehicle !== -1 && fleetCopy[indexOfVehicle].quantity !== "" && fleetCopy[indexOfVehicle].quantity > 0) {
				fleetCopy[indexOfVehicle].quantity -= 1;
				setData({
					...data,
					fleet: fleetCopy
				});
			}
		}
	}

	const handleChangeVehicleQuantity = (e, vehicleId) => {
		let fleetCopy = [...data.fleet];
		const indexOfVehicle = fleetCopy.findIndex(v => v.id === vehicleId);

		if (indexOfVehicle !== -1 && (e.target.value === '' || (!isNaN(parseInt(e.target.value)) && e.target.value >= 0))) {
			fleetCopy[indexOfVehicle].quantity = e.target.value === '' ? '' : parseInt(e.target.value);
			setData({
				...data,
				fleet: fleetCopy
			});
		}
	}

	const onBlurVehicleQuantity = (vehicleId) => () => {
		let fleetCopy = [...data.fleet];
		const indexOfVehicle = fleetCopy.findIndex(v => v.id === vehicleId);

		if (indexOfVehicle !== -1 && fleetCopy[indexOfVehicle].quantity === '') {
			fleetCopy[indexOfVehicle].quantity = 0;
			setData({
				...data,
				fleet: fleetCopy
			});
		} else if (indexOfVehicle !== -1 && !isNaN(parseInt(fleetCopy[indexOfVehicle].quantity.toString())) && parseInt(fleetCopy[indexOfVehicle].quantity.toString()) > 0) {
			fleetCopy[indexOfVehicle].quantity = parseInt(fleetCopy[indexOfVehicle].quantity.toString());
			setData({
				...data,
				fleet: fleetCopy
			});
			resetError('fleet')();
		}
	}

	const resetError = (stateName) => () => {
		setErrors({
			...errors,
			[stateName]: {
				error: false,
				message: ''
			}
		})
	}

	const [loadingResponseVat, setLoadingResponseVat] = useState(false);
	const [isViesAddress, setIsViesAddress] = useState(false);
	const populateEnterpriseData = async () => {
		if (!!data.country && !!data.vat) {
			const country = countriesPrefixes.find(c => c.id === data.country);
			const tokenData = await api.getAccessToken();

			if (country && tokenData.success === true) {
				setLoadingResponseVat(true);
				const accessToken = tokenData.responseBody.access_token;
				const enterpriseData = await api.getEnterpriseData(accessToken, country.isoCode, data.vat);

				if (enterpriseData.success === true) {
					setIsViesAddress(true);
					const addressData = await api.getAddressDataFromNominatim(enterpriseData.responseBody.address);

					let errorResetObj = {
						enterpriseName: {
							error: false,
							message: ''
						},
						address: {
							error: false,
							message: ''
						},
						prefix: {
							error: false,
							message: ''
						}
					};

					if (addressData.success && addressData.data.length > 0 && formatNominatimAddress(addressData.data[0]).success) {
						const formattedAddress = formatNominatimAddress(addressData.data[0]);

						setData({
							...data,
							enterpriseName: enterpriseData.responseBody.name,
							address: formattedAddress.data.address,
							city: formattedAddress.data.city,
							region: formattedAddress.data.region,
							postalCode: formattedAddress.data.postalCode,
						});

						errorResetObj = {
							...errorResetObj,
							city: {
								error: false,
								message: ''
							},
							region: {
								error: false,
								message: ''
							},
							postalCode: {
								error: false,
								message: ''
							}
						}
					} else {
						setData({
							...data,
							enterpriseName: enterpriseData.responseBody.name,
							address: enterpriseData.responseBody.address,
							city: '',
							region: '',
							postalCode: ''
						});
					}

					setErrors({
						...errors,
						...errorResetObj,
					});
				} else {
					setData({
						...data,
						enterpriseName: '',
						address: '',
						city: '',
						region: '',
						postalCode: '',
					});
				}

				setLoadingResponseVat(false);
			}
		}
	}

	const canPass = (errorObj) => {
		let can = true;
		for (let property in errorObj) {
			if (errorObj[property].error) {
				can = false;
			}
		}
		return can;
	}

	const isThereAnError = useMemo(() => {
		return !canPass(errors);
	}, [errors]);

	const save = async () => {
		if (formCompleted === false) {
			const errorObj = validateForm(data);

			if (canPass(errorObj)) {
				// remove excess data from the fleet object
				const copyData = { ...data };
				const copyFleet = [...copyData.fleet].map(v => {
					return {
						vehicleId: v.id,
						quantity: v.quantity
					}
				}).filter(v => v.quantity > 0);
				copyData.fleet = copyFleet;

				// Adding countryISO code
				copyData['countryISO'] = countriesPrefixes.find(country => country.id === copyData.country)?.isoCode;

				// Add token 
				copyData['token'] = null;

				// Add to telephone the prefix
				copyData.telephone = countriesPrefixes.find(country => country.id === copyData.prefix)?.prefix + parseInt(copyData.telephone).toString();

				// Add isViessAddress
				copyData['isViesAddress'] = isViesAddress;

				// Trim Vat
				copyData['vat'] = copyData["vat"].replace(/\s+/g, '').trim();

				setLoadingResponseData(true)
				const responseData = await api.saveLead(copyData);
				if (responseData && responseData.success) {
					setFormCompleted(true);
				} else if (responseData.success === false && responseData.statusCode === 409) {
					setErrorDuplicatedApplication(true);
				} else {
					setErrorResponseData(true);
				}
				setLoadingResponseData(false)
			} else {
				setErrors({
					...errors,
					...errorObj
				});
			}
		}
	}

	// IMPORTANT: if errorApi == true -> don't show anything
	if (errorApi === true) {
		return (
			<ErrorFallback />
		)
	}

	return (
		<Layout
			activeElementId={activeElementId}
			callbackOnScroll={handleScroll}
			callbackSetOffsetTop={(e) => setContainerOffsetTop(e)}
			callbackOnSubmit={save}
			isLoadingSubmit={loadingResponseData}
			isCompleted={formCompleted}
		>
			<Modal visible={errorResponseData === true} callbackClose={() => setErrorResponseData(false)}>
				<div className="text-center">
					<p className="text-[15px] text-[#757575] font-medium">
						{t('modal.errorResponseData')}
					</p>
				</div>
			</Modal>

			<Modal visible={errorDuplicatedApplication === true} callbackClose={() => setErrorDuplicatedApplication(false)}>
				<div className="text-center">
					<p className="text-[15px] text-[#757575] font-medium">
						{t('modal.errorDuplicatedApplication')}
					</p>
				</div>
			</Modal>

			<Modal visible={vendorTypeModalOpen === true} callbackClose={() => setVendorTypeModalOpen(false)}>
				<div>
					<ul className="text-[15px] text-[#757575]">
						{
							vendorTypes && vendorTypes.length > 0 && vendorTypes.map((v, index) => {
								if (!!v.description) {
									return (
										<li key={index} className="mb-3">
											<strong>{v.value}:</strong> {v.description}
										</li>
									)
								}
							})
						}
					</ul>
				</div>
			</Modal>

			<div className="border-b border-darkBeige">
				<StepContainer
					number="1"
					title={t('sections.enterpriseData')}
					id="enterprise-data"
					scrollValue={actualScroll}
					containerOffsetTop={containerOffsetTop}
					callbackOnEnter={() => setActiveElementId('enterprise-data')}
				>
					<div className="grid grid-cols-12 gap-[20px] mb-10">
						<Select
							label={t('inputs.country.label')}
							placeholder={t('inputs.country.placeholder')}
							name="country"
							containerClass="col-span-12 lg:col-span-4"
							options={countriesPrefixes}
							optionValueKey="id"
							callbackOnChange={(e) => onChangeText('country', e)}
							value={data.country}
							hasError={errors.country && errors.country.error}
							errorMessage={errors.country && errors.country.message}
							callbackOnFocus={resetError('country')}
							hasIcon={true}
							iconKey={'flagIcon'}
						/>
						<InputText
							label={isCee === true ? t('inputs.vat.label') : t('inputs.vat.genericTaxCode')}
							type="text"
							placeholder={isCee === true ? t('inputs.vat.placeholder') : t('inputs.vat.genericTaxCodePlaceholder')}
							name="vat"
							containerClass="col-span-12 lg:col-span-4"
							callbackOnChange={(e) => onChangeText('vat', e)}
							value={data.vat}
							hasError={errors.vat && errors.vat.error}
							errorMessage={errors.vat && errors.vat.message}
							callbackOnFocus={resetError('vat')}
							callbackOnBlur={populateEnterpriseData}
							loading={loadingResponseVat}
						/>
						<InputText
							label={t('inputs.enterpriseData.label')}
							type="text"
							placeholder={t('inputs.enterpriseData.placeholder')}
							name="enterpriseName"
							containerClass="col-span-12 lg:col-span-4"
							value={data.enterpriseName}
							callbackOnChange={(e) => onChangeText('enterpriseName', e)}
							hasError={errors.enterpriseName && errors.enterpriseName.error}
							errorMessage={errors.enterpriseName && errors.enterpriseName.message}
							callbackOnFocus={resetError('enterpriseName')}
						/>
					</div>

					<div>
						<h4 className="text-gray text-lg font-medium mb-[10px]">{t('inputs.registeredOffice')}</h4>

						<div className="mb-[20px]">
							<InputText
								label={t('inputs.address.label')}
								type="text"
								placeholder={t('inputs.address.placeholder')}
								name="address"
								callbackOnChange={(e) => onChangeText('address', e)}
								value={data.address}
								hasError={errors.address && errors.address.error}
								errorMessage={errors.address && errors.address.message}
								callbackOnFocus={resetError('address')}
							/>
						</div>


						<div className="grid grid-cols-11 gap-[20px]">
							<InputText
								label={t('inputs.city.label')}
								type="text"
								placeholder={t('inputs.city.placeholder')}
								name="city"
								callbackOnChange={(e) => onChangeText('city', e)}
								containerClass="col-span-12 lg:col-span-6"
								value={data.city}
								hasError={errors.city && errors.city.error}
								errorMessage={errors.city && errors.city.message}
								callbackOnFocus={resetError('city')}
							/>

							<InputText
								label={t('inputs.region.label')}
								type="text"
								placeholder={t('inputs.region.placeholder')}
								name="region"
								callbackOnChange={(e) => onChangeText('region', e)}
								containerClass="col-span-6 lg:col-span-3"
								value={data.region}
								hasError={errors.region && errors.region.error}
								errorMessage={errors.region && errors.region.message}
								callbackOnFocus={resetError('region')}
							/>

							<InputText
								label={t('inputs.postalCode.label')}
								type="text"
								placeholder={t('inputs.postalCode.placeholder')}
								name="postalCode"
								callbackOnChange={(e) => onChangeText('postalCode', e)}
								containerClass="col-span-6 lg:col-span-2"
								value={data.postalCode}
								hasError={errors.postalCode && errors.postalCode.error}
								errorMessage={errors.postalCode && errors.postalCode.message}
								callbackOnFocus={resetError('postalCode')}
							/>
						</div>
					</div>
				</StepContainer>

				<StepContainer
					number="2"
					title={t('sections.contactData')}
					id="contact-data"
					scrollValue={actualScroll}
					containerOffsetTop={containerOffsetTop}
					callbackOnEnter={() => setActiveElementId('contact-data')}
				>
					<div className="grid grid-cols-12 gap-[20px] mb-[20px]">
						<InputText
							label={t('inputs.contactName.label')}
							type="text"
							placeholder={t('inputs.contactName.placeholder')}
							name="contactName"
							containerClass="col-span-12 lg:col-span-6"
							callbackOnChange={(e) => onChangeText('contactName', e)}
							value={data.contactName}
							hasError={errors.contactName && errors.contactName.error}
							errorMessage={errors.contactName && errors.contactName.message}
							callbackOnFocus={resetError('contactName')}
						/>
						<InputText
							label={t('inputs.contactSurname.label')}
							type="text"
							placeholder={t('inputs.contactSurname.placeholder')}
							name="contactSurname"
							containerClass="col-span-12 lg:col-span-6"
							callbackOnChange={(e) => onChangeText('contactSurname', e)}
							value={data.contactSurname}
							hasError={errors.contactSurname && errors.contactSurname.error}
							errorMessage={errors.contactSurname && errors.contactSurname.message}
							callbackOnFocus={resetError('contactSurname')}
						/>
					</div>

					<div className="grid grid-cols-10 gap-[20px] mb-[20px]">
						<Select
							label={t('inputs.prefix.label')}
							placeholder={t('inputs.prefix.placeholder')}
							name="prefix"
							containerClass="col-span-5 lg:col-span-2"
							options={countriesPrefixes}
							optionValueKey="id"
							optionLabelKey="prefix"
							callbackOnChange={(e) => onChangeText('prefix', e)}
							value={data.prefix}
							hasError={errors.prefix && errors.prefix.error}
							errorMessage={errors.prefix && errors.prefix.message}
							callbackOnFocus={resetError('prefix')}
							hasIcon={true}
							iconKey={'flagIcon'}
							searchValue="name"
						/>

						<InputText
							label={t('inputs.telephone.label')}
							type="text"
							placeholder={t('inputs.telephone.placeholder')}
							name="telephone"
							containerClass="col-span-7 lg:col-span-3"
							callbackOnChange={(e) => onChangeText('telephone', e)}
							value={data.telephone}
							hasError={errors.telephone && errors.telephone.error}
							errorMessage={errors.telephone && errors.telephone.message}
							callbackOnFocus={resetError('telephone')}
						/>

						<Select
							label={t('inputs.role.label')}
							placeholder={t('inputs.role.placeholder')}
							name="role"
							containerClass="col-span-12 lg:col-span-5"
							options={roles}
							optionValueKey="id"
							optionLabelKey="value"
							callbackOnChange={(e) => onChangeText('role', e)}
							value={data.role}
							hasError={errors.role && errors.role.error}
							errorMessage={errors.role && errors.role.message}
							callbackOnFocus={resetError('role')}
						/>
					</div>

					<div className="grid grid-cols-12 gap-[20px]">
						<InputText
							label={t('inputs.email.label')}
							type="text"
							placeholder={t('inputs.email.placeholder')}
							name="email"
							containerClass="col-span-12 lg:col-span-6"
							callbackOnChange={(e) => onChangeText('email', e)}
							value={data.email}
							hasError={errors.email && errors.email.error}
							errorMessage={errors.email && errors.email.message}
							callbackOnFocus={resetError('email')}
						/>
						<InputText
							label={t('inputs.confirmEmail.label')}
							type="text"
							placeholder={t('inputs.confirmEmail.placeholder')}
							name="confirmEmail"
							containerClass="col-span-12 lg:col-span-6"
							callbackOnChange={(e) => onChangeText('confirmEmail', e)}
							value={data.confirmEmail}
							hasError={errors.confirmEmail && errors.confirmEmail.error}
							errorMessage={errors.confirmEmail && errors.confirmEmail.message}
							callbackOnFocus={resetError('confirmEmail')}
						/>
					</div>
				</StepContainer>

				<StepContainer
					number="3"
					title={t('sections.fleetDetails')}
					id="fleet-data"
					scrollValue={actualScroll}
					containerOffsetTop={containerOffsetTop}
					callbackOnEnter={() => setActiveElementId('fleet-data')}
				>
					<div>
						<div className="flex items-end mb-5">
							<h4 className="text-gray text-lg font-medium mr-2">{t('inputs.supplierType')}</h4>
							<div className="cursor-pointer" onClick={() => setVendorTypeModalOpen(true)}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 7.121 7.121 3 12 3C16.879 3 21 7.121 21 12C21 16.879 16.879 21 12 21C7.121 21 3 16.879 3 12ZM5 12C5 15.794 8.206 19 12 19C15.794 19 19 15.794 19 12C19 8.206 15.794 5 12 5C8.206 5 5 8.206 5 12ZM11 11L11 16H13V11H11ZM11 8V10H13V8H11Z" fill="#CF0A2C" />
								</svg>
							</div>
						</div>
						<div className="flex flex-wrap">
							{
								vendorTypes && vendorTypes.length > 0 && vendorTypes.map((vendorType, index) => {
									return (
										<Tab
											key={index}
											label={vendorType.value}
											value={vendorType.id}
											active={data.vendorType === vendorType.id}
											callback={handleTabChange}
										/>
									);
								})
							}
						</div>

						{
							errors.vendorType && errors.vendorType.error &&
							<div>
								<Error errorMessage={errors.vendorType.message} />
							</div>
						}

						{
							!!data.vendorType && vendorTypes.find(vendor => vendor.id === data.vendorType).hasFleet &&

							<div className="mt-[20px]">
								<h4 className="text-gray text-lg font-medium mb-5">{t('inputs.fleet')}</h4>

								<div className="grid grid-cols-12 gap-[20px] leading-[17.5px]">
									{
										vehicles.map((vehicleGroup, index) => {
											return (
												<div
													key={index}
													className={"bg-beige border border-darkBeige rounded-[8px] p-5 col-span-12 lg:col-span-6"}
												>
													<h6 className="font-bold text-[15px] text-gray mb-5">{vehicleGroup.type}</h6>

													<ul>
														{
															vehicleGroup.vehicles.length > 0 && vehicleGroup.vehicles.map((v, i) => {
																return (
																	<li key={i + '-' + index} className="flex items-center mb-5">
																		{/* Counter */}
																		<div className="flex items-center">
																			<div onClick={removeVehicleToFleet(v.id)} className="bg-white select-none text-center text-red text-[18px] leading-[20px] cursor-pointer h-5 w-5 rounded-full">-</div>
																			<div className="input-text select-none text-[13px] flex items-center justify-center h-6 w-6 mx-[10px] bg-white text-black">
																				<input
																					type="text"
																					inputMode="numeric"
																					value={findFleetVehicle(v.id)?.quantity}
																					className="w-full h-full text-center"
																					onChange={(e) => handleChangeVehicleQuantity(e, v.id)}
																					onBlur={onBlurVehicleQuantity(v.id)}
																				/>
																			</div>
																			<div onClick={addVehicleToFleet(v.id)} className="bg-white select-none text-center text-red text-[18px] leading-[20px] cursor-pointer h-5 w-5 rounded-full">+</div>
																		</div>
																		<div className="ml-5 mr-[10px]" dangerouslySetInnerHTML={{ __html: v.icon }} />
																		<div className="text-gray text-[15px]">{v.name}</div>
																	</li>
																);
															})
														}
													</ul>
												</div>
											);
										})
									}
								</div>

								{
									errors.fleet && errors.fleet.error &&
									<div className="mt-3">
										<Error errorMessage={errors.fleet.message} />
									</div>
								}
							</div>
						}
					</div>
				</StepContainer>
			</div>

			{/* Checkboxes */}
			<div className="py-[30px] mb-[40px] lg:mb-[80px]">
				<Checkbox
					name="privacy"
					callback={e => onChangeText('privacyAccepted', e)}
					label={t('inputs.privacyAccepted')}
					className="mb-[10px]"
					hasError={errors.privacyAccepted && errors.privacyAccepted.error}
					errorMessage={errors.privacyAccepted && errors.privacyAccepted.message}
					callbackOnFocus={resetError('privacyAccepted')}
				/>

				<Checkbox
					name="marketing"
					callback={e => onChangeText('marketingAccepted', e)}
					label={t('inputs.marketingAccepted')}
					className="mb-[10px]"
				/>

				<Checkbox
					name="collaborationConsent"
					callback={e => onChangeText('collaborationConsent', e)}
					label={t('inputs.collaborationConsent')}
					className="mb-[10px]"
				/>

				<div className="text-[#757575] text-[11px] font-medium leading-[13px]">*{t('requiredFields')}</div>

				{
					isThereAnError &&
					<div className="mt-[30px] text-xs font-bold text-red flex items-center">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[26px] shrink-0 h-[26px] mr-[5px]">
							<path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
						</svg>
						<div>
							{t('errors.isThereAnError')}
						</div>
					</div>
				}
			</div>

		</Layout>
	);
}

export default App;
