import React, { useState, useEffect, useRef } from "react";
import Error from "../error/Error";

const Select = ({
    options,
    optionValueKey = 'value',
    optionLabelKey = 'name',
    label,
    placeholder,
    value,
    name,
    callbackOnChange,
    callbackOnFocus,
    containerClass,
    hasError = false,
    errorMessage,
    hasIcon = false,
    iconKey,
    searchValue = ''
}) => {

    const [queryValue, setQueryValue] = useState('');
    const [mappedOptions, setMappedOptions] = useState([]);

    useEffect(() => {
        if (options && options.length > 0) {
            setMappedOptions(mapInitialOptions());
        }
    }, [options])

    const mapInitialOptions = () => {
        return [...options].map((option) => {
            return {
                ...option,
                visible: true
            }
        });
    }

    const inputRef = useRef(null);

    const handleChangeSearch = (e) => {
        const copyMappedOptions = [...mappedOptions].map((option) => {
            const isInList = !!option[optionLabelKey].toLowerCase().includes(e.target.value.toLowerCase());

            let isInListBySearchValue = false;
            if (!!searchValue) {
                isInListBySearchValue = !!option[searchValue].toLowerCase().includes(e.target.value.toLowerCase());
            }

            return {
                ...option,
                visible: (isInList || isInListBySearchValue)
            }
        })
        setMappedOptions(copyMappedOptions);
        setQueryValue(e.target.value);
    }

    const handleOptionClick = (valueSelected) => () => {
        setMappedOptions(mapInitialOptions());
        callbackOnChange(valueSelected);
    }

    const [open, setOpen] = useState(false);
    const selectRef = useRef(null);
    useEffect(() => {
        const listener = (e) => {
            if (selectRef && selectRef.current && !selectRef.current.contains(e.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('click', listener);
        return () => {
            document.removeEventListener('click', listener);
        }
    }, []);

    useEffect(() => {
        if (!!value) {
            setQueryValue(displayByValue()[optionLabelKey])
        }
    }, [value, options]);

    const openClose = (e) => {
        if (open === false && callbackOnFocus) {
            callbackOnFocus();
            inputRef.current.focus();
        } else {
            inputRef.current.blur();
        }

        setOpen(!open);
    }

    const displayByValue = () => {
        return options.find(option => {
            return option[optionValueKey] === value
        });
    }

    const handleBlurInput = () => {
        if (!!value) {
            setQueryValue(displayByValue()[optionLabelKey]);
        }
    }

    return (
        <div ref={selectRef} onClick={openClose} className={"select-none " + containerClass}>
            {
                label &&
                <label className="block mb-1 text-gray font-medium uppercase text-xs" htmlFor={name}>{label}</label>
            }

            <div
                className={"relative focus:outline input-text text-black text-[15px] bg-beige border border-darkBeige rounded-[8px] p-[10px] w-full" + (hasError ? ' border-red' : '')}
                name={name}
                id={name}
            >
                <div className="flex justify-between items-center overflow-hidden whitespace-nowrap relative h-full">
                    <div className="w-full flex items-center cursor-default whitespace-nowrap overflow-hidden">
                        {
                            !!value && hasIcon && !!iconKey &&
                            <div className="mr-[5px] mt-[2px] shrink-0" dangerouslySetInnerHTML={{ __html: displayByValue()[iconKey] }} />
                        }

                        {/* Input hidden to search */}
                        <input
                            ref={inputRef}
                            className="w-[calc(100%-30px)] bg-transparent border-none outline-transparent text-black text-[15px] bg-beige input-text h-auto"
                            type={'text'}
                            placeholder={placeholder}
                            name={name}
                            id={name}
                            value={queryValue}
                            onChange={handleChangeSearch}
                            onBlur={handleBlurInput}
                        />
                    </div>

                    <div className="absolute top-1/2 -translate-y-1/2 right-0 z-8">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[15px] h-[15px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                </div>

                {
                    open &&
                    <ul className="absolute z-10 top-full bg-beige w-full left-0 border border-darkBeige max-h-[220px] overflow-y-auto overflow-x-hidden whitespace-nowrap">
                        <li value="" className="text-gray overflow-x-hidden whitespace-nowrap text-ellipsis px-[10px] bg-darkBeige opacity-80" disabled>
                            {placeholder}
                        </li>

                        {
                            mappedOptions.map((option, index) => {
                                return (
                                    option.visible &&
                                    <li
                                        key={index}
                                        value={option[optionValueKey]}
                                        onClick={handleOptionClick(option[optionValueKey])}
                                        className="px-[10px] overflow-x-hidden whitespace-nowrap flex items-center cursor-default hover:bg-darkBeige"
                                    >
                                        {
                                            hasIcon && iconKey &&
                                            <div className="mr-[5px] mt-[2px]" dangerouslySetInnerHTML={{ __html: option[iconKey] }} />
                                        }

                                        <span className="text-ellipsis overflow-x-hidden whitespace-nowrap max-w-[calc(100%-40px)]">
                                            {option[optionLabelKey]}
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                }
            </div>

            {
                hasError &&
                <Error errorMessage={errorMessage} />
            }
        </div>
    );
}

export default Select;