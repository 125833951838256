import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultLanguage } from "./configs/languages/languages";

import { TRANSLATION_EN } from "./localization/en/translation";
import { TRANSLATION_IT } from "./localization/it/translation";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: {
            default: [defaultLanguage]
        },
        resources: {
            en: {
                translation: TRANSLATION_EN
            },
            it: {
                translation: TRANSLATION_IT
            }
        }
    });

export default i18n;