import i18n from '../../i18n';

export const validateRequired = (value) => {
    return !!value;
}

export const populateValidationRequired = (message = null) => {
    return {
        error: true,
        message: !!message ? message : i18n.t('errors.requiredField')
    };
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const populateValidationEmail = () => {
    return {
        error: true,
        message: i18n.t('errors.wrongEmailFormat')
    };
}

export const validateConfirmEmail = (email, confirmEmail) => {
    return email === confirmEmail;
}

export const populateValidationConfirmEmail = () => {
    return {
        error: true,
        message: i18n.t('errors.wrongConfirmEmail')
    };
}

export const validateTelephone = (tel) => {
    return ((/^\d*$/.test(tel)) && tel.length > 4 && tel.length < 15 && !isNaN(parseInt(tel))) ;    
}

export const validateNumeric = (string) => {
    return /^\d*$/.test(string);
}

export const validateNotNumeric = (string) => {
    return !(/\d$/.test(string));
}

export const populateValidationNameFormat = () => {
    return {
        error: true,
        message: i18n.t('errors.wrongFormatName')
    };
}

export const populateValidationSurnameFormat = () => {
    return {
        error: true,
        message: i18n.t('errors.wrongFormatSurname')
    };
}

export const populateValidationTelephone = () => {
    return {
        error: true,
        message: i18n.t('errors.wrongTelephoneFormat')
    };
}

export const validateFleetVehicles = (vendorType, fleet) => {
    if (!!vendorType) {
        if (vendorType.hasFleet === false) {
            return true;
        }

        for (let i = 0; i <= fleet.length - 1; i++) {
            const vehicle = fleet[i];
            if (vehicle.quantity > 0) {
                return true
            }
        }
    }

    return false;
}

export const populateValidationFleetVehicles = () => {
    return {
        error: true,
        message: i18n.t('errors.vehicles')
    }
}