import React from "react";

const Button = ({
    children,
    className,
    callback,
    loading = false
}) => {

    const handleCallback = () => {
        if (callback && !loading) {
            callback();
        }
    }

    return (
        <button
            className={'text-[20px] text-white bg-red rounded-[8px] leading-[23.5px] font-medium block px-[20px] hover:opacity-80 transition py-[8.5px] pb-[10px]' + (className ? ' ' + className : '')}
            onClick={handleCallback}
            disabled={loading}
        >
            {
                !loading &&
                children 
            }
            {
                loading &&
                <div
                    className="spinner-border"
                    role="status"
                >
                    <span className="sr-only">Loading...</span>
                </div>
            }
        </button>
    );
}

export default Button;