import React from "react";
import './Spinner.css';

const Spinner = ({
    height = 19,
    width = 19,
    color = '#cf0a2c'
}) => {
    return (
        <div
            className="spinner-border"
            style={{
                width: width + "px",
                height: height + "px",
                color: color,
                borderLeftColor: color,
                borderTopColor: color,
                borderBottomColor: color,
            }}
            role="status"
        >
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Spinner;