import React from "react";

const SidebarStep = ({
    number,
    label,
    id,
    isActive = false
}) => {
    return (
        <div className={"flex items-center" + (isActive ? " grow" : "") + (number == 2 ? " mx-[10px] lg:mx-0" : "")}>
            <a href={'#' + id} className={"flex text-white items-center shrink-0" + (isActive && number == 3 ? " order-2 lg:order-none ml-[10px] lg:ml-0" : "")}>
                <div className={"h-[44px] w-[44px] text-[20px] font-bold leading-[44px] rounded-full border border-white flex items-center justify-center" + (isActive ? ' bg-white text-red' : '')}>
                    {number}
                </div>

                <div className={"pl-[10px] uppercase label-sidebar-step" + (isActive ? ' text-[15px] font-bold' : ' hidden lg:block lg:text-[11px] font-medium')}>
                    {label}
                </div>
            </a>
            <div className={"grow h-[1px] bg-white" + (!isActive ? " hidden lg:block" : "") + (number == 3 ? ' order-1 lg:order-none ml-0 lg:ml-[10px]' : ' ml-[10px]')}></div>
        </div>
    );
}

export default SidebarStep;